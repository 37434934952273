










import { Component, Vue, Prop } from 'vue-property-decorator'
import { InputType } from '@/store/OrganizationQuestions'

@Component
export default class extends Vue {
  @Prop() value!: boolean

  inputType = InputType

  get vm(): boolean {
    return this.value
  }

  set vm(selected: boolean) {
    this.$emit('input', selected)
  }
}
