import config from '@/config'
import store from '@/store'
import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export const ErrorMsg = {
  responseError: (status: number) => 'API response error status:' + status,
  FetchOrganizationQuestionsFailure: 'オリジナル項目の取得に失敗しました',
  SettingOrganizationQuestionsFailure: 'オリジナル項目の設定に失敗しました',
} as const

export type Setting = {
  questionItems: Array<QuestionItem>
  isViewOrganizationName: boolean
}

export const InputType = {
  Number: 1,
  YesOrNo: 2,
  Free: 3,
}
export type InputType = typeof InputType[keyof typeof InputType]

export type QuestionItem = {
  name: string | undefined
  order: number
  isRequired: boolean
  inputType: InputType
}

@Module({ dynamic: true, namespaced: true, name: 'organizationQuestions', store })
class Mod extends VuexModule {
  questionItems: Array<QuestionItem> = []
  isViewOrganizationName = false

  get questionItemByOrder(): CallableFunction {
    return (byOrder: number): QuestionItem | undefined =>
      this.questionItems.find((questionItem) => questionItem.order === byOrder)
  }

  @Mutation
  setOrganizationQuestions(setting: Setting): void {
    this.questionItems = setting.questionItems
    this.isViewOrganizationName = setting.isViewOrganizationName
  }

  @Action({ commit: 'setOrganizationQuestions', rawError: true })
  async fetchOrganizationQuestions(): Promise<Array<Setting>> {
    try {
      const res = await axios.get(`${config.enterprise.apiUrl}/organization-questions`)
      return res.data
    } catch {
      throw new Error(ErrorMsg.FetchOrganizationQuestionsFailure)
    }
  }

  @Action({ rawError: true })
  async settingOrganizationQuestions(setting: {
    questionItems: Array<QuestionItem>
    isViewOrganizationName: boolean
  }): Promise<void> {
    try {
      const questionItems = setting.questionItems.filter((item) => item.name)

      await axios.put(`${config.enterprise.apiUrl}/organization-questions`, {
        questionItems,
        isViewOrganizationName: setting.isViewOrganizationName,
      } as Setting)
    } catch {
      throw new Error(ErrorMsg.SettingOrganizationQuestionsFailure)
    }
  }
}

export default getModule(Mod)
