














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop() value!: string

  clear(): void {
    this.$emit('input', '')
  }
}
