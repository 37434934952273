






























import VABox from 'va/widgets/VABox.vue'
import menuStore from '@/store/Menu'
import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/atoms/Spinner.vue'
import QuestionItemTable from '@/components/organisms/QuestionItemSetting/QuestionItemTable.vue'
import OrganizationQuestionsStore, { QuestionItem } from '@/store/OrganizationQuestions'

@Component({
  components: { VABox, Spinner, QuestionItemTable },
})
export default class extends Vue {
  isLoading = false

  questionItems: Array<QuestionItem> = []
  isViewOrganizationName = false

  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })

    this.isLoading = true
    try {
      await OrganizationQuestionsStore.fetchOrganizationQuestions()
      this.isViewOrganizationName = OrganizationQuestionsStore.isViewOrganizationName
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message)
      }
    } finally {
      this.isLoading = false
    }
  }

  async completeSetting(): Promise<void> {
    this.isLoading = true
    try {
      await OrganizationQuestionsStore.settingOrganizationQuestions({
        isViewOrganizationName: this.isViewOrganizationName,
        questionItems: this.questionItems,
      })
      alert('オリジナル項目を更新しました。')
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message)
      }
    } finally {
      this.isLoading = false
    }
  }
}
