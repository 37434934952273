



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import InputPart from '@/components/organisms/QuestionItemSetting/InputPart.vue'
import RequirePart from '@/components/organisms/QuestionItemSetting/RequirePart.vue'
import InputTypePart from '@/components/organisms/QuestionItemSetting/InputTypePart.vue'
import OrganizationQuestionsStore, { InputType, QuestionItem } from '@/store/OrganizationQuestions'

@Component({ components: { InputPart, RequirePart, InputTypePart } })
export default class extends Vue {
  private readonly params: {
    questionItem1: QuestionItem
    questionItem2: QuestionItem
    questionItem3: QuestionItem
  } = {
    questionItem1: { name: undefined, order: 1, isRequired: true, inputType: InputType.Free },
    questionItem2: { name: undefined, order: 2, isRequired: true, inputType: InputType.Free },
    questionItem3: { name: undefined, order: 3, isRequired: true, inputType: InputType.Free },
  }

  @Watch('params', { deep: true })
  input(): void {
    this.$emit('input', Object.values(this.params))
  }

  @Watch('questionItems')
  initializeIfNotInput(): void {
    if (!this.params.questionItem1.name && OrganizationQuestionsStore.questionItemByOrder(1)) {
      this.params.questionItem1 = OrganizationQuestionsStore.questionItemByOrder(1)
    }
    if (!this.params.questionItem2.name && OrganizationQuestionsStore.questionItemByOrder(2)) {
      this.params.questionItem2 = OrganizationQuestionsStore.questionItemByOrder(2)
    }
    if (!this.params.questionItem3.name && OrganizationQuestionsStore.questionItemByOrder(3)) {
      this.params.questionItem3 = OrganizationQuestionsStore.questionItemByOrder(3)
    }
  }

  get questionItems(): Array<QuestionItem> {
    return OrganizationQuestionsStore.questionItems
  }
}
