









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop() value!: boolean

  get vm(): boolean {
    return this.value
  }

  set vm(selected: boolean) {
    this.$emit('input', selected)
  }
}
